import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/observable/of';

import { HttpClient, HttpHeaders, HttpRequest, HttpResponse } from '@angular/common/http';
import { environment } from '../../../environments/environment';

import { LoginService } from './login.service';
import { Product } from '../models/product';
import { Lesson } from '../models/lesson';
import { Timeline } from '../models/timeline';
import { Category } from '../models/category';
import { Answer } from '../models/answer';
import { Exam } from '../models/exam';
import { AppUser } from '../models/app.user';

import { CookieService } from 'angular2-cookie/services/cookies.service';

@Injectable()
export class GeneralService {

  private URL: string = environment.urlbase;
  private headers: HttpHeaders;

  constructor(
    private http: HttpClient,
    private loginService: LoginService,
    private cookieService: CookieService
  ) {
    this.headers = this.loginService.getHeaders();
  }

  timeline(): Observable<Timeline> {
    this.headers = this.loginService.getHeaders();
    const timelineExt: string = 'timeline' + this.authExtension();
    return this.http.get<Timeline>(this.URL + timelineExt, {headers: this.headers});
  }

  authExtension(): string {
    let ext: string = '';
    if (this.loginService.isAuthenticated()) {
      ext += '/auth';
    }
    return ext;
  }

  defaultMainCategory(): Category {
    return   {
      "name": "treinamentos",
      "title": "TREINAMENTOS",
      "short_desc": "Para pilotos e comissários",
      "order": 1,
      "col": 2,
      "logged": false,
      "questions": false,
      "lessons": true
    }
  }

  defaultPracticeTestCategory(): Category {
    return  {
      "name": "simulados",
      "title": "SIMULADOS",
      "short_desc": "Teste o seu conhecimento",
      "order": 4,
      "col": 4,
      "logged": true,
      "questions": true,
      "lessons": false
    }
  }

  register(model: AppUser): Observable<AppUser> {
    return this.http.post<AppUser>(this.URL + 'user', model);
  }

  updateProfile(model: AppUser): Observable<AppUser> {
    this.headers = this.loginService.getHeaders();
    return this.http.put<AppUser>(this.URL + 'user', model, {headers: this.headers});
  }

  store(model: any, modelName: string, json: boolean = false): void {
    this.cookieService.put(modelName, json ? JSON.stringify(model) : model);
  }

  get(modelName: string, json: boolean = false): any {
    if (this.cookieService.get(modelName) === null || this.cookieService.get(modelName) === undefined) {
      return false;
    }
    return json ? JSON.parse(this.cookieService.get(modelName)) : this.cookieService.get(modelName);
  }

  product(model: Product | number): Observable<Product> {
    this.headers = this.loginService.getHeaders();
    const productsExt: string = 'products/' + (typeof(model) == 'number' ? model : model.id) + this.authExtension();
    return this.http.get<Product>(this.URL + productsExt, {headers: this.headers});
  }

  availableProducts(){
    this.headers = this.loginService.getHeaders();
    const availableProductsExt: string = 'my-available-products';
    return this.http.get(this.URL + availableProductsExt, {headers: this.headers});
  }

  finish(model: Lesson, token: string = null): Observable<any> {
    const newHeader: HttpHeaders = this.loginService.getHeaders(token);
    const URL: string = environment.urlbase + 'lessons/' + (typeof(model) == 'number' ? model : model.id) + '/seen';
    return this.http.request(new HttpRequest('POST', URL, {}, {headers: newHeader}))
    .map(
      (res: HttpResponse<any>): any => {
        return { status: res.status, ...res.body };
      }
    );
  }

  initExam(model: Product | number): Observable<Exam> {
    this.headers = this.loginService.getHeaders();
    const initialAt: string = this.nowDateTime();
    const productId: number = typeof (model) == 'number' ? model : model.id;
    const body: Exam = {
      product_id: productId,
      initial_at: initialAt
    }
    return this.http.post<Exam>(this.URL + 'exam', body , {headers: this.headers});
  }

  saveExam(model: number, answers: Answer[]): Observable<Exam> {
    this.headers = this.loginService.getHeaders();
    const finalAt: string = this.nowDateTime();
    const total: number = answers.length;
    const totalScore: number = (answers.filter((x: Answer): boolean => x.right_answer)).length;
    const totalPercent: number = Math.round(((totalScore / total) * 100));
    const body: Exam = {
      total_score: totalScore,
      total_percent: totalPercent,
      total: total,
      final_at: finalAt,
      answers: answers
    }
    return this.http.put<Exam>(this.URL + 'exam/' + model, body, {headers: this.headers});
  }

  listExams(): Observable<Exam[]> {
    this.headers = this.loginService.getHeaders();
    return this.http.get<Exam[]>(this.URL + 'exams', {headers: this.headers});
  }

  private nowDateTime(): string {
    const now: Date = new Date(Date.now());
    let date: string = now.getFullYear() + '-';
    const month: string = this.numberTostring((now.getMonth() + 1));
    const day: string = this.numberTostring(now.getDate());
    date += month + '-' + day + ' ';
    const hours: string = this.numberTostring(now.getHours());
    const minutes: string = this.numberTostring(now.getMinutes());
    return date + hours + ':' + minutes;
  }

  private numberTostring(number: number): string {
    return number < 10 ? ('0' + number) : number.toString(); 
  }

  deviceDetection(device: string): boolean {
    const isAndroid: RegExpMatchArray =  navigator.userAgent.match(/Android/i);
    const isBlackBerry: RegExpMatchArray = navigator.userAgent.match(/BlackBerry/i);
    const isiOS: RegExpMatchArray = navigator.userAgent.match(/iPhone|iPad|iPod/i);
    if (device == 'Android') return isAndroid !== null;
    if (device == 'BlackBerry') return isBlackBerry !== null;
    if (device == 'iOS') return isiOS !== null;
  }

  getLayoutElement(): HTMLElement {
    const layoutContainerCollection: HTMLCollection = document.getElementsByClassName('layout-container');
    if (layoutContainerCollection !== null && layoutContainerCollection !== undefined) {
      const layoutContainer: HTMLDivElement = layoutContainerCollection[0] as HTMLDivElement;
      if (layoutContainer !== null && layoutContainer !== undefined) {
        const contentCollection: HTMLCollection = layoutContainer.getElementsByClassName('content');
        if (contentCollection !== null && contentCollection !== undefined) {
          const content: HTMLDivElement = contentCollection[0] as HTMLDivElement;
          if (content !== null && content !== undefined) {
            const columnsCollection: HTMLCollection = content.getElementsByClassName('columns');
            if (columnsCollection !== null && columnsCollection !== undefined) {
              const columns: HTMLDivElement = columnsCollection[0] as HTMLDivElement;
              if (columns !== null && columns !== undefined) {
                const nbLayoutColumnCollection: HTMLCollection = columns.getElementsByTagName('nb-layout-column');
                if (nbLayoutColumnCollection !== null && nbLayoutColumnCollection !== undefined) {
                  const nbLayoutColumn: HTMLElement = nbLayoutColumnCollection[0] as HTMLElement;
                  if (nbLayoutColumn !== null && nbLayoutColumn !== undefined) {
                    return nbLayoutColumn;
                  }
                }
              }
            }
          }
        }
      }
    }
    return undefined;
  }
}
