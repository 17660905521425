import { Injectable } from '@angular/core';
import 'rxjs/add/observable/of';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { LoginService } from './login.service';
import { Device } from '../models/device';


@Injectable()
export class DeviceService {

  private URL: string = environment.urlbase;
  private headers: HttpHeaders;

  constructor(
    private http: HttpClient,
    private loginService: LoginService,
  ) {
    this.headers = this.loginService.getHeaders();
  }

  destroy(device: Device){
    this.headers = this.loginService.getHeaders();
    const deviceUri: string = 'device/' + (typeof(device) == 'number' ? device : device.id);
    console.log('URL', this.URL + deviceUri);
    return this.http.delete(this.URL + deviceUri, {headers: this.headers});
  }
}
