import { Injectable } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { Location } from '@angular/common';
import { filter } from 'rxjs/operators';

declare const ga: any;

@Injectable()
export class AnalyticsService {
  private enabled: boolean;

  constructor(
    private location: Location, 
    private router: Router
  ) {
    this.enabled = false;
  }

  trackPageViews(): void {
    if (this.enabled) {
      this.router.events.pipe(
        filter((event): boolean => event instanceof NavigationEnd),
      )
      .subscribe((): void => {
        ga('send', {hitType: 'pageview', page: this.location.path()});
      });
    }
  }

  trackEvent(eventName: string): void {
    if (this.enabled) {
      ga('send', 'event', eventName);
    }
  }
}
