import { Component, Input, AfterContentInit, Attribute} from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { NgbModalRef } from '@ng-bootstrap/ng-bootstrap';

import { Router } from '@angular/router';
import { Timeline } from '../../../../@core/models/timeline';
import { DeviceService } from '../../../../@core/data/device.service';
@Component({
  selector: 'flightclass-devices-dialog',
  templateUrl: './dialog.devices.component.html',
  styleUrls: ['./dialog.devices.component.scss'],
})

export class DialogDevicesComponent implements AfterContentInit {

  modelName: string = 'Aula';
  modelGender: string = 'a';
  @Input() model: Timeline;
  public devices = [];

  constructor(
    private activeModal: NgbActiveModal,
    private router: Router,
    private deviceService: DeviceService
  ) { }

  ngAfterContentInit(): void {
    if (this.model === null || this.model === undefined) {
      this.connectionError();
    }
  }

  private connectionError(): void {
    this.router.navigate(['produtos']);
  }

  closeModal(data: any = undefined): void {
    data !== undefined && data !== null ? this.activeModal.close(data) : this.activeModal.dismiss();
  }

  revogar(device){
    let resp = {};
    this.deviceService.destroy(device).subscribe(
      (done: any): void => {
        resp = done;
        let index = this.devices.indexOf(device);
        this.devices.splice(index, 1);
      },
    );
    return resp;
  }
}
