import { ExtraOptions, RouterModule, Routes } from '@angular/router';
import { NgModule } from '@angular/core';


const routes: Routes = [
  { path: '', loadChildren: 'app/system/system.module#SystemModule' },
  { path: 'termos-de-uso', loadChildren: 'app/terms-use/terms-use.module#TermsUseModule' },
  { path: 'privacidade', loadChildren: 'app/privacy/privacy.module#PrivacyModule' },
  { path: 'login', loadChildren: 'app/auth/auth.module#AuthModule' },
  { path: 'cadastro', loadChildren: 'app/registration/registration.module#RegistrationModule' },
  { path: 'logout', redirectTo: 'login/logout', pathMatch: 'full' },
  { path: '**', redirectTo: '', pathMatch: 'full' },
];

const config: ExtraOptions = {
  useHash: false
};

@NgModule({
  imports: [RouterModule.forRoot(routes, config)],
  exports: [RouterModule],
})
export class AppRoutingModule {
}
