import { Injectable } from '@angular/core';

@Injectable()
export class BackupService {

  constructor() { }

  getQuestionsJSON(): any {
    return { 
      "showNavigationButtons": true,
      "pageNextText": "Próxima",
      "pagePrevText": "Voltar",
      "sendResultOnPageNext": true,
      "pages": [
        {
          "questions": [
            { 
              "type": "radiogroup", 
              "name": "relacionamento", 
              "title": "Qual seu estado civil?", 
              "isRequired": true, 
              "colCount": 1, 
              "choices": ["Solteira", "Casada", "Divorciada", "Viúva", "Em relacionamento estável (namoro)"] 
            }
          ]
        },
        {
          "questions": [
            {         
              "type": "radiogroup", 
              "name": "escolaridade", 
              "title": "Qual sua escolaridade?", 
              "isRequired": true, 
              "colCount": 1, 
              "choices": ["Ensino Fundamental", "Ensino Médio", "Ensino superior", "Pós Graduação"] 
            }
          ]
        },
        {
          "questions": [
            {  
              "type": "radiogroup", 
              "name": "dor", 
              "title": "Você tem alguma dor?", 
              "isRequired": true, 
              "colCount": 1, 
              "choices": ["Costas", "Joelhos", "Região Cervical", "Outros"] 
            }
          ]
        },
        {
          "questions": [
            {  
              "type": "radiogroup", 
              "name": "cigarro", 
              "title": "QUANTO AO CONSUMO DE CIGARRO, COMO VOCÊ SE IDENTIFICA?", 
              "isRequired": true, 
              "colCount": 1, 
              "choices": ["Nunca Fumei", "Parei há mais de 6 meses", "Parei a menos de 6 meses", "Sou fumante"] 
            }
          ]
        },
        {
          "questions": [
            {  
              "type": "radiogroup", 
              "name": "alcool", 
              "title": "QUANTO AO CONSUMO DE BEBIDAS ALCOOLICAS, COMO VOCÊ SE IDENTIFICA?", 
              "isRequired": true, 
              "colCount": 1, 
              "choices": ["Nunca", "Menos do que uma vez ao mês", "De 2 a 4 vezes por mês", "De 4 a 6 vezes por semana", "Todos ou quase todos os dias"] 
            }
          ]
        },
        {
          "title": "Quão satisfeito você está com sua saúde e aparência física? Sendo 0 a pior nota e 10 a melhor nota.",
          "questions": [
            {  
              "type": "rating", 
              "name": "aparencia_hoje", 
              "title": "Como se vê hoje?", 
              "isRequired": true, 
              "rateMin": 1,
              "rateMax": 10,
              "rateStep": 1
            },
            {  
              "type": "rating", 
              "name": "aparencia_desejo", 
              "title": "Como deseja estar?", 
              "isRequired": true, 
              "rateMin": 1,
              "rateMax": 10,
              "rateStep": 1,
            }
          ]
        },
        {
          "title": "Como avalia sua tranquilidade mental e estabilidade emocional? Sendo 0 a pior nota e 10 a melhor nota.",
          "questions": [
            {  
              "type": "rating", 
              "name": "emocional_hoje", 
              "title": "Como se vê hoje?", 
              "isRequired": true, 
              "rateMin": 1,
              "rateMax": 10,
              "rateStep": 1
            },
            {  
              "type": "rating", 
              "name": "emocional_desejo", 
              "title": "Como deseja estar?", 
              "isRequired": true, 
              "rateMin": 1,
              "rateMax": 10,
              "rateStep": 1,
            }
          ]
        },
        {
          "title": "Como avalia a qualidade das tuas relações, especialmente as íntimas? Sendo 0 a pior nota e 10 a melhor nota.",
          "questions": [
            {  
              "type": "rating", 
              "name": "relacoes_hoje", 
              "title": "Como se vê hoje?", 
              "isRequired": true, 
              "rateMin": 1,
              "rateMax": 10,
              "rateStep": 1
            },
            {  
              "type": "rating", 
              "name": "relacoes_desejo", 
              "title": "Como deseja estar?", 
              "isRequired": true, 
              "rateMin": 1,
              "rateMax": 10,
              "rateStep": 1,
            }
          ]
        },
        {
          "title": "Quão eficaz é administrando teu tempo? É capaz de alcançar e completar as metas que te propões no dia a dia? Sendo 0 a pior nota e 10 a melhor nota.",
          "questions": [
            {  
              "type": "rating", 
              "name": "tempo_hoje", 
              "title": "Como se vê hoje?", 
              "isRequired": true, 
              "rateMin": 1,
              "rateMax": 10,
              "rateStep": 1
            },
            {  
              "type": "rating", 
              "name": "tempo_desejo", 
              "title": "Como deseja estar?", 
              "isRequired": true, 
              "rateMin": 1,
              "rateMax": 10,
              "rateStep": 1,
            }
          ]
        },
        {
          "title": "Quão satisfeita está com tua vida profissional? Sendo 0 a pior nota e 10 a melhor nota.",
          "questions": [
            {  
              "type": "rating", 
              "name": "profissional_hoje", 
              "title": "Como se vê hoje?", 
              "isRequired": true, 
              "rateMin": 1,
              "rateMax": 10,
              "rateStep": 1
            },
            {  
              "type": "rating", 
              "name": "profissional_desejo", 
              "title": "Como deseja estar?", 
              "isRequired": true, 
              "rateMin": 1,
              "rateMax": 10,
              "rateStep": 1,
            }
          ]
        },
        {
          "title": "Como tu pontuaria a forma com que lida com seu dinheiro? Sendo 0 a pior nota e 10 a melhor nota.",
          "questions": [
            {  
              "type": "rating", 
              "name": "dinheiro_hoje", 
              "title": "Como se vê hoje?", 
              "isRequired": true, 
              "rateMin": 1,
              "rateMax": 10,
              "rateStep": 1
            },
            {  
              "type": "rating", 
              "name": "dinheiro_desejo", 
              "title": "Como deseja estar?", 
              "isRequired": true, 
              "rateMin": 1,
              "rateMax": 10,
              "rateStep": 1,
            }
          ]
        },
        {
          "title": "Como pontuaria seu sentido de propósito e significado de vida? Está ativamente contribuindo e celebrando? Sendo 0 a pior nota e 10 a melhor nota.",
          "questions": [
            {  
              "type": "rating", 
              "name": "proposito_hoje", 
              "title": "Como se vê hoje?", 
              "isRequired": true, 
              "rateMin": 1,
              "rateMax": 10,
              "rateStep": 1
            },
            {  
              "type": "rating", 
              "name": "proposito_desejo", 
              "title": "Como deseja estar?", 
              "isRequired": true, 
              "rateMin": 1,
              "rateMax": 10,
              "rateStep": 1,
            }
          ]
        },
        {
          "title": "Como você avalia seu nível de ansiedade hoje? Sendo 0 nada ansiosa e 10 muito ansiosa.",
          "questions": [
            {  
              "type": "rating", 
              "name": "ansiedade_hoje", 
              "title": "Como se vê hoje?", 
              "isRequired": true, 
              "rateMin": 1,
              "rateMax": 10,
              "rateStep": 1
            },
            {  
              "type": "rating", 
              "name": "ansiedade_desejo", 
              "title": "Como deseja estar?", 
              "isRequired": true, 
              "rateMin": 1,
              "rateMax": 10,
              "rateStep": 1,
            }
          ]
        },
        {
          "title": "Como é seu comprometimento com os treinos e Alimentação Saudável pra antingir seu objetivo? Sendo 0 a pior nota e 10 a melhor nota.",
          "questions": [
            {  
              "type": "rating", 
              "name": "comprometimento_hoje", 
              "title": "Como se vê hoje?", 
              "isRequired": true, 
              "rateMin": 1,
              "rateMax": 10,
              "rateStep": 1
            },
            {  
              "type": "rating", 
              "name": "comprometimento_desejo", 
              "title": "Como deseja estar?", 
              "isRequired": true, 
              "rateMin": 1,
              "rateMax": 10,
              "rateStep": 1,
            }
          ]
        },
        {
          "title": "Como você se sente em relação a disposição ao longo do dia? Sendo 0 a pior nota e 10 a melhor nota.",
          "questions": [
            {  
              "type": "rating", 
              "name": "disposicao_hoje", 
              "title": "Como se vê hoje?", 
              "isRequired": true, 
              "rateMin": 1,
              "rateMax": 10,
              "rateStep": 1
            },
            {  
              "type": "rating", 
              "name": "disposicao_desejo", 
              "title": "Como deseja estar?", 
              "isRequired": true, 
              "rateMin": 1,
              "rateMax": 10,
              "rateStep": 1,
            }
          ]
        },
        {
          "title": "Como você avalia seu sono hoje? Sendo 0 a pior nota e 10 a melhor nota.",
          "questions": [
            {  
              "type": "rating", 
              "name": "sono_hoje", 
              "title": "Como se vê hoje?", 
              "isRequired": true, 
              "rateMin": 1,
              "rateMax": 10,
              "rateStep": 1
            },
            {  
              "type": "rating", 
              "name": "sono_desejo", 
              "title": "Como deseja estar?", 
              "isRequired": true, 
              "rateMin": 1,
              "rateMax": 10,
              "rateStep": 1,
            }
          ]
        },
        {
          "title": "Como você avalia sua alimentação? Sendo 0 a pior nota e 10 a melhor nota.",
          "questions": [
            {  
              "type": "rating", 
              "name": "alimentacao_hoje", 
              "title": "Como se vê hoje?", 
              "isRequired": true, 
              "rateMin": 1,
              "rateMax": 10,
              "rateStep": 1
            },
            {  
              "type": "rating", 
              "name": "alimentacao_desejo", 
              "title": "Como deseja estar?", 
              "isRequired": true, 
              "rateMin": 1,
              "rateMax": 10,
              "rateStep": 1,
            }
          ]
        },
        {
          "title": "Como você avalia sua autoestima? Sendo 0 a pior nota e 10 a melhor nota.",
          "questions": [
            {  
              "type": "rating", 
              "name": "autoestima_hoje", 
              "title": "Como se vê hoje?", 
              "isRequired": true, 
              "rateMin": 1,
              "rateMax": 10,
              "rateStep": 1
            },
            {  
              "type": "rating", 
              "name": "autoestima_desejo", 
              "title": "Como deseja estar?", 
              "isRequired": true, 
              "rateMin": 1,
              "rateMax": 10,
              "rateStep": 1,
            }
          ]
        },
        {
          "questions": [
            {  
              "type": "radiogroup", 
              "name": "pq_problema_coracao", 
              "title": "PAR-Q 1 - Alguma vez um médico lhe disse que você possui um problema do coração e lhe recomendou que só fizesse atividade física sob supervisão médica?", 
              "isRequired": true, 
              "colCount": 1, 
              "choices": ["Sim", "Não"] 
            }
          ]
        },
        {
          "questions": [
            {  
              "type": "radiogroup", 
              "name": "pq_dor_peito_rotina", 
              "title": "PAR-Q 2 - Você sente dor no peito, causada pela prática de atividade física?", 
              "isRequired": true, 
              "colCount": 1, 
              "choices": ["Sim", "Não"] 
            }
          ]
        },
        {
          "questions": [
            {  
              "type": "radiogroup", 
              "name": "pq_dor_peito_recente", 
              "title": "PAR-Q 3 - Você sentiu dor no peito no último mês?", 
              "isRequired": true, 
              "colCount": 1, 
              "choices": ["Sim", "Não"] 
            }
          ]
        },
        {
          "questions": [
            {  
              "type": "radiogroup", 
              "name": "pq_desmaios", 
              "title": "PAR-Q 4 - Você tende a perder a consciência ou cair, como resultado de tonteira ou desmaio?", 
              "isRequired": true, 
              "colCount": 1, 
              "choices": ["Sim", "Não"] 
            }
          ]
        },
        {
          "questions": [
            {  
              "type": "radiogroup", 
              "name": "pq_problema_osseo", 
              "title": "PAR-Q 5 - Você tem algum problema ósseo ou muscular que poderia ser agravado com a prática de atividade física?", 
              "isRequired": true, 
              "colCount": 1, 
              "choices": ["Sim", "Não"] 
            }
          ]
        },
        {
          "questions": [
            {  
              "type": "radiogroup", 
              "name": "pq_medicamentos", 
              "title": "PAR-Q 6 - Algum médico já lhe recomendou o uso de medicamentos para a sua pressão arterial, para circulação ou coração?", 
              "isRequired": true, 
              "colCount": 1, 
              "choices": ["Sim", "Não"] 
            }
          ]
        },
        {
          "questions": [
            {  
              "type": "radiogroup", 
              "name": "pq_outro_problema", 
              "title": "PAR-Q 7 - Você tem consciência, através da sua própria experiência ou aconselhamento médico, de alguma outra razão física que impeça sua prática de atividade física sem supervisão médica?", 
              "isRequired": true, 
              "colCount": 1, 
              "choices": ["Sim", "Não"] 
            }
          ]
        },
        {
          "questions": [
            {  
              "type": "radiogroup", 
              "name": "pq_ciente", 
              "title": "INFORMATIVO: Se você respondeu SIM para alguma das respostas anteriores (PAR-Q), você precisa consultar um médico antes de realizar atividade física. DECLARAÇÃO: Declaro estar ciente dos termos acima, bem como estar em plenas condições de saúde e estar autorizado por meu médico a praticar atividades físicas em alta intensidade, assumindo total responsabilidade pelo meu estado de saúde, isentando o RQX System e seus colaboradores de responsabilidades sobre qualquer acontecimento durante os treinos orientados nos vídeos. O conteúdo deste material é fornecido apenas para fins informativos e educativos e não pretende ser de cunho médico nem de diagnóstico ou prescrição de qualquer outra área. A disponibilidade dos conteúdos está baseada no completo entendimento de que não oferece nenhum tipo de prescrição médica ou de qualquer outra área. Não substitui a orientação médica e/ou prescrições de profissionais da área da saúde e outras, assim como não tem a intenção de ser usada como tratamento para qualquer doença, condição de saúde ou lesão. É imperativo que antes de começar qualquer plano alimentar ou treinamento, inclusive descritos neste programa, que você tenha a aprovação completa de um nutricionista e um educador físico. Mesmo que você não tenha problemas de saúde conhecidos, é aconselhável consultar um médico antes de fazer grandes mudanças em seu estilo de vida. Você assume expressamente tais riscos e renuncia qualquer alegação que eles possam ter contra o RQX System ou da equipe distribuidora do material, como resultado de qualquer lesão ou doença futura incorridos no uso, ou mau uso, deste.", 
              "isRequired": true, 
              "colCount": 1, 
              "choices": ["Sim", "Não"] 
            }
          ]
        },
      ]
    };
  }

  
}
