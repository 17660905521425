import { Component, OnDestroy, OnInit } from '@angular/core';
import { NbMediaBreakpointsService, NbMenuItem, NbSidebarService, NbThemeService, NbMenuService, NbMenuBag } from '@nebular/theme';

import { Router } from '@angular/router';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';

import { UserService } from '../../../@core/data/users.service';
import { LoginService } from '../../../@core/data/login.service';
import { GeneralService } from '../../../@core/data/general.service';
import { LayoutService } from '../../../@core/utils';
import { Timeline } from '../../../@core/models/timeline';
import { HeaderDialogComponent } from './dialog.headers.component';
import { DialogDevicesComponent } from './devices-dialog/dialog.devices.component';
import { Lesson } from '../../../@core/models/lesson';
import { Exam } from '../../../@core/models/exam';

import { map, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

@Component({
  selector: 'flightclass-header',
  styleUrls: ['./header.component.scss'],
  templateUrl: './header.component.html',
})
export class HeaderComponent implements OnInit, OnDestroy {

  private destroy$: Subject<void> = new Subject<void>();
  userPictureOnly: boolean = false;
  user: any;

  themes: any[] = [
    {
      value: 'default',
      name: 'Light',
    },
    {
      value: 'dark',
      name: 'Dark',
    },
    {
      value: 'cosmic',
      name: 'Cosmic',
    },
    {
      value: 'corporate',
      name: 'Corporate',
    },
  ];

  currentTheme: string = 'corporate';

  userMenu: NbMenuItem[] = [
    { title: 'Sair', link: 'logout' }
  ];
  extrasMenuItems: boolean = false;

  openingPopUp: boolean = false;

  constructor(
    private modal: NgbModal,
    private menuService: NbMenuService,
    private sidebarService: NbSidebarService,
    private themeService: NbThemeService,
    private userService: UserService,
    private layoutService: LayoutService,
    private breakpointService: NbMediaBreakpointsService,
    private loginService: LoginService,
    private generalService: GeneralService,
    private router: Router,
  ) {
    this.loadExtraMenuItems();
  }

  ngOnInit(): void {
    this.currentTheme = this.themeService.currentTheme;
    this.userService.getUsers()
      .pipe(takeUntil(this.destroy$))
      .subscribe((users: any): void => this.user = users.nick);
    const { xl } = this.breakpointService.getBreakpointsMap();
    this.themeService.onMediaQueryChange()
      .pipe(
        map(([, currentBreakpoint]): boolean => currentBreakpoint.width < xl),
        takeUntil(this.destroy$),
      )
      .subscribe((isLessThanXl: boolean): boolean => this.userPictureOnly = isLessThanXl);
    this.themeService.onThemeChange()
      .pipe(
        map(({ name }): string => name),
        takeUntil(this.destroy$),
      )
      .subscribe(themeName => this.currentTheme = themeName);
    this.menuService.onItemClick()
      .subscribe(
        (menu: NbMenuBag): void => {
          if (menu.item.data !== undefined && menu.item.data.id !== undefined && menu.item.data.id == 'history') {
            this.openHistory();
          }
        }
      );
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  changeTheme(themeName: string): void {
    this.themeService.changeTheme(themeName);
  }

  toggleSidebar(): boolean {
    this.sidebarService.toggle(true, 'menu-sidebar');
    this.layoutService.changeLayoutSize();
    return false;
  }

  navigateHome(): boolean {
    this.router.navigate(['/']);
    return false;
  }

  navigateLogin(): boolean {
    this.router.navigate(['login']);
    return false;
  }

  isAuthenticated(): boolean {
    return this.loginService.isAuthenticated();
  }

  private loadExtraMenuItems(): void {
    if (!this.extrasMenuItems) {
      this.generalService.timeline()
      .subscribe(
        (done: Timeline): void => {
          if (this.exists(done.whatsapp)) {
            this.extrasMenuItems = true;
            let URL: string = 'https://api.whatsapp.com/send?phone=' + done.whatsapp;
            if (this.exists(done.whatsapp_msg)) {
              URL += '&text=' + done.whatsapp_msg;
            }
            this.userMenu.unshift({
              title: 'Suporte', url: URL, target: '_blank'
            });
          }
          if (this.exists(done.privacy)) {
            this.extrasMenuItems = true;
            this.userMenu.unshift({
              title: 'Política de Privacidade', url: done.privacy, target: '_blank'
            });
          }
          if (this.exists(done.terms)) {
            this.extrasMenuItems = true;
            this.userMenu.unshift({
              title: 'Termos de Uso', url: done.terms, target: '_blank'
            });
          }
          this.addHystoryItem();
        },
        (): void => {
          this.addHystoryItem();
        }
      );
    }
  }

  private exists(object: Object): boolean {
    return object !== null && object !== undefined && object != '';
  }

  private addHystoryItem(): void {
    this.userMenu.unshift({
      title: 'Editar Perfil',
      link: '/cadastro/editar'
    });

    this.userMenu.unshift({
      title: 'Histórico',
      data: { id: 'history'}
    });
  }

  private openHistory(): void {
    let model: Lesson = {
      name: 'Histórico',
      description: '<p>Por favor aguarde enquanto seu histórico é carregado</p>'
    }
    const activeModal: NgbModalRef = this.modal.open(HeaderDialogComponent, {size: 'lg', container: 'nb-layout'});
    activeModal.componentInstance.model = model;
    if (!this.openingPopUp) {
      this.openingPopUp = true;
      this.generalService.listExams()
      .subscribe(
        (done: Exam[]): void => {
          model.description = '';
          done.filter((x: Exam): boolean => x.product !== null && x.product !== undefined).forEach((item: Exam): void => {
            model.description += this.fancyHistory(item);
          });
          activeModal.componentInstance.model = model;
          setTimeout((): void => {
            this.openingPopUp = false;
          }, 1000);
        },
        (): void => {
          model.description = '<h3>Houve um erro!</h3><p>Não foi possível carregar seu histórico.</p>';
          activeModal.componentInstance.model = model;
          this.openingPopUp = false;
        }
      );
      activeModal.result.then(
        (): void => { },
        (): void => { },
      );
    }
  }

  private openDevices(): void {
    let model: Lesson = {
      name: 'Dispositivos',
      description: '<p>Por favor aguarde um instante...</p>'
    }
    const activeModal: NgbModalRef = this.modal.open(DialogDevicesComponent, {size: 'lg', container: 'nb-layout'});
    activeModal.componentInstance.model = model;
    
    this.openingPopUp = true;
    this.generalService.timeline()
    .subscribe(function (done) {
      activeModal.componentInstance.devices = done.devices;
    });
    
    activeModal.result.then(
      (): void => { },
      (): void => { },
    );
  }

  private fancyHistory(model: Exam): string {
    let label: string;
    const finalAt: string = this.exists(model.final_at) ? model.final_at : model.initial_at;
    label = '<h2>' + model.product.name + '</h2>';
    label += '<h3>Dia ' + this.fancyDate(this.dateStringToDate(model.initial_at)) + ', às ';
    label += this.fancyHour(this.dateStringToDate(model.initial_at)) + ' <strong>&#8226;</strong> Duração: ';
    label += this.fancyDuration(this.dateStringToDate(model.initial_at), this.dateStringToDate(finalAt));
    label += ' <strong>&#8226; ' + (this.exists(model.total_score) ? model.total_score :'0') + ' de ';
    label += (this.exists(model.total) ? model.total : '0') + ' quest' + (model.total > 1 ? 'ões' : 'ão');
    label += ' (' + (this.exists(model.total_percent) ? model.total_percent : '0') + '%)</strong>.</h3><br>';
    return label;
  }

  private dateStringToDate(dateString: string): Date {
    const year: number = parseInt(dateString.substr(0, 4));
    const month: number = parseInt(dateString.substr(5, 2)) - 1;
    const day: number = parseInt(dateString.substr(8, 2));
    const hours: number = parseInt(dateString.substr(11, 2));
    const minutes: number = parseInt(dateString.substr(14, 2));
    const date: Date = new Date(year, month, day, hours, minutes);
    return date;
  }

  private fancyDate(date: Date): string {
    const day: string = date.getDate().toString();
    const month: string = date.getMonth() < 9 ? ('0' + (date.getMonth() + 1)) : (date.getMonth() + 1).toString();
    return day + '/' + month;
  }

  private fancyHour(date: Date): string {
    const hours: string = date.getHours().toString();
    const minutes: string = date.getMinutes() < 10 ? ('0' + date.getMinutes()) : date.getMinutes().toString();
    return hours + 'h' + minutes;
  }

  private fancyDuration(init: Date, end: Date): string {
    const timestamp: number = (end.getTime() - init.getTime()) / 1000;
    const hourInSeconds: number = 60 * 60;
    const hourDuration: number = Math.floor((timestamp / hourInSeconds));
    const minuteDuration: number = Math.ceil((((timestamp / hourInSeconds) - hourDuration) * 60));
    console.log(init, end, timestamp, hourDuration, minuteDuration);
    let label: string = '';
    let minuteLabel: string = '';
    if (hourDuration > 0) {
      label = hourDuration + 'h';
      minuteLabel = '0';
    }
    if (minuteDuration >= 0) {
      label += ((minuteDuration < 10 ? minuteLabel : '') + minuteDuration) + 'min';
    }
    return label;
  }

  
}
