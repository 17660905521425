import { Component, Input, AfterContentInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

import { Lesson } from '../../../@core/models/lesson';

import { Router } from '@angular/router';

@Component({
  selector: 'flightclass-header-dialog',
  templateUrl: './dialog.header.component.html',
  styleUrls: ['./dialog.header.component.scss'],
})

export class HeaderDialogComponent implements AfterContentInit {

  modelName: string = 'Aula';
  modelGender: string = 'a';

  @Input() model: Lesson;

  constructor(
    private activeModal: NgbActiveModal,
    private router: Router
  ) { }

  ngAfterContentInit(): void {
    if (this.model === null || this.model === undefined) {
      this.connectionError();
    }
  }

  private connectionError(): void {
    this.router.navigate(['produtos']);
  }

  closeModal(data: any = undefined): void {
    data !== undefined && data !== null ? this.activeModal.close(data) : this.activeModal.dismiss();
  }
}
