import { Product } from "./product";
import { Category } from "./category";

export class Timeline {
  logged?: boolean;

  whatsapp?: string;
  whatsapp_msg?: string;
  urlplayer?: string
  terms?: string;
  terms_content?: string;
  privacy?: string;
  privacy_content?: string;
  register?: true;
  url_signature?: string;
  apple_buy?: boolean;
  idapplesignature?: string;

  categories: Category[];
  productsByCategory: {
    name?: string;
    title?: string;
    products?: Product[];
  }[];
  devices: [];
};
