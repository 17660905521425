import { Injectable } from '@angular/core';
import 'rxjs/add/observable/of';
import { environment } from '../../../environments/environment';

@Injectable()
export class BucketService {

  public accessKeyId: string = environment.accessKeyId;
  public secretAccessKey: string = environment.secretAccessKey;
  public Bucket: string = environment.Bucket;
  public Region: string = environment.Region;

  constructor() { }

  data(): any {
    return {
      accessKeyId: this.accessKeyId,
      secretAccessKey: this.secretAccessKey,
      Bucket: this.Bucket,
      Region: this.Region
    }
  }
}
