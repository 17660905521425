import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { CookieService } from 'angular2-cookie/services/cookies.service';
import { environment } from '../../../environments/environment';
import { StorageService } from './storage.service';
import { OneSignalService } from './onesignal.service';
import { AppUser } from '../models/app.user';

@Injectable()
export class LoginService {

  private URL: string = environment.urlbase + 'login';
  private logged: boolean = false;

  constructor(
    private http: HttpClient,
    private cookieService: CookieService,
    private storageService: StorageService,
    private onesignalService: OneSignalService,
  ) { }

  check(): Promise<boolean> {
    return new Promise<boolean>(
      (logged: any): void => {
        if (this.logged) {
          logged(true);
        } else {
          const email: string = this.cookieService.get('email');
          const password: string = this.cookieService.get('password');
          if (email !== undefined && password !== undefined) {
            this.login(email, password)
            .then(
              (valid: boolean): void => {
                logged(valid);
              },
            );
          } else {
            logged(false);
          }
        }
      },
    );
  }

  login(email: string, password: string, cellphone_code='', email_code=''): Promise<boolean> {
    return new Promise<boolean>(
      (logged: any): void => {
        this.http.post(this.URL, { email: email, password: password, cellphone_code: cellphone_code, email_code: email_code, type: 'PWA' })
        .subscribe(
          (auth: AppUser): void => {
            this.purge();
            this.logged = true;
            this.cookieService.put('email', email);
            this.cookieService.put('password', password);
            this.cookieService.put('name', auth.name !== undefined ? auth.name : '');
            this.cookieService.put('picture', auth.image !== undefined && auth.image !== null ? auth.image : 'assets/images/cockpit.jpeg');
            this.cookieService.put('image', auth.image);
            this.cookieService.put('phone_number', auth.phone_number);
            this.cookieService.put('doc_number', auth.doc_number);
            this.cookieService.put('goal', auth.goal);
            this.cookieService.put('token', auth.api_token);
            this.cookieService.put('token_id_watermark', auth.token_id_watermark);
            this.storageService.store(auth.api_token, 'token');
            this.cookieService.put('id', auth.id.toString());
            this.onesignalService.checkIfSubscribed();
            logged(true);
          },
          (error): void => {
            this.logged = false;
            this.purge();
            this.cookieService.put('messageError', error.error.msg);
            logged(false);
          },
        );
      },
    );
  }

  isAuthenticated(): boolean {
    return this.logged;
  }

  logout(): void {
    this.logged = false;
    this.purge();
  }

  resetPassword(data: any): Observable<any> {
    return this.http.post((environment.urlbase + 'resetpassword'), data);
  }

  forceLogin(data: AppUser): void {
    this.purge();
    this.cookieService.put('email', data.email);
    this.cookieService.put('password', data.plain_password);
    this.cookieService.put('name', data.name !== undefined ? data.name : '');
    this.cookieService.put('picture', data.image !== undefined && data.image !== null ? data.image : 'assets/images/cockpit.jpeg');
    this.cookieService.put('image', data.image);
    this.cookieService.put('phone_number', data.phone_number);
    this.cookieService.put('doc_number', data.doc_number);
    this.cookieService.put('goal', data.goal);
    this.cookieService.put('token', data.api_token);
    this.storageService.store(data.api_token, 'token');
    this.cookieService.put('id', data.id.toString());
  }

  purge(): void {
    this.cookieService.remove('email');
    this.cookieService.remove('password');
    this.cookieService.remove('name');
    this.cookieService.remove('picture');
    this.cookieService.remove('image');
    this.cookieService.remove('phone_number');
    this.cookieService.remove('doc_number');
    this.cookieService.remove('goal');
    this.cookieService.remove('token');
    this.cookieService.remove('id');
  }

  getToken(): string {
    const storageToken: string = this.storageService.restore('token');
    return storageToken != '' && storageToken !== null && storageToken !== undefined ? storageToken : this.cookieService.get('token');    
  }

  userID(): string {
    return this.cookieService.get('id');
  }

  getHeaders(externalToken = null): HttpHeaders {
    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.set('Accept', 'application/json');
    const token = externalToken == null ? this.getToken() : externalToken;
    if (token !== undefined && token != '' && token !== null) {
      headers = headers.set('Authorization', 'Bearer ' + token);
    }
    return headers;
  }

  checkEmailExists(emailLogin){
    return this.http.post(environment.urlbase + 'checkEmailExists', { emailLogin: emailLogin});
  }

  sendNewCodeEmail(emailLogin){
    return this.http.post(environment.urlbase + 'sendNewCodeEmail', { emailLogin: emailLogin});
  }

  sendNewCodeSms(emailLogin){
    return this.http.post(environment.urlbase + 'sendNewCodeSms', { emailLogin: emailLogin});
  }

  validateCodeSms(emailLogin, cellphoneCode){
    return this.http.post(environment.urlbase + 'validateCodeSms', { emailLogin: emailLogin, cellphoneCode: cellphoneCode});
  }

  validateCodeEmail(emailLogin, emailCode){
    return this.http.post(environment.urlbase + 'validateCodeEmail', { emailLogin: emailLogin, emailCode: emailCode});
  }
}
