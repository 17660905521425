import { Injectable } from '@angular/core';
import { CookieService } from 'angular2-cookie';
import { StorageService } from './storage.service';

let OneSignal: any;

@Injectable()
export class OneSignalService {

  ONE_SIGNAL_APP_ID: string = '2008f616-a589-4819-914d-3fb66c2dfa1d';
  ONE_SIGNAL_REST_API_KEY: string = 'ZDNmMjBlN2EtOTc5ZC00ZDJhLTk4MmItZWU3NDNlOTVlOTdl';
  Safari_Web_ID: string = 'web.onesignal.auto.2bc028a8-3e83-466a-979b-b4e85ca9934f';

  oneSignalInit: boolean;
  oneSignalId: string;
  user_id: number;

  constructor(
    private cookieService: CookieService,
    private storageService: StorageService
  ) {
    this.oneSignalInit = this.storageService.restore('onesignal') == 'initialized';
    this.oneSignalId = this.cookieService.get('onesignalId');
  }

  public init(): void {
    this.oneSignalInit = this.storageService.restore('onesignal') == 'initialized';
    this.oneSignalInit ? console.log('Already Initialized') : this.initOneSignal();
  }

  private initOneSignal(): void {
    OneSignal = window['OneSignal'] || [];
    console.log('Init OneSignal');
    OneSignal.push(['init', {
      appId: this.ONE_SIGNAL_APP_ID,
      safari_web_id: this.Safari_Web_ID,
      allowLocalhostAsSecureOrigin: true,
      notifyButton: {
        enable: true,
      },
      welcomeNotification: {
        disable: true
      }
    }]);
    console.log('OneSignal Initialized');
    this.storageService.store('onesignal', 'initialized');
    this.checkIfSubscribed();
    this.setUserID()
  }

  public setUserID(): void {
    this.user_id = parseInt(this.cookieService.get('id'));
    if (Number.isNaN(this.user_id) || this.user_id == null || this.user_id == undefined || this.user_id == NaN) {
      OneSignal.deleteTag("user_id", 
      (tag: string): void => {
        console.log('OneSignal Tag Deleted', tag);
      });
      OneSignal.removeExternalUserId(this.user_id);
    } else {
      OneSignal.sendTag('user_id', this.user_id, 
        (tagsSent: string): void => {
          console.log('OneSignal Tag Sent', tagsSent);
        }
      );
      OneSignal.setExternalUserId(this.user_id);
    }
  }

  public checkIfSubscribed(): void {
    OneSignal.push(
      (): void => {
        OneSignal.isPushNotificationsEnabled(
          (isEnabled: boolean): void => {
            if (isEnabled) {
              console.log('Push notifications are enabled!');
              this.getUserID();
              this.setUserID();
            } else {
              console.log('Push notifications are not enabled yet.');
              this.subscribe();
            }
          }, 
          (): void => {
            console.log('Push permission not granted');
          }
        );
      }
    );
  }

  private getUserID(): void {
    OneSignal.getUserId().then(
      (userId: string | number): void => {
        console.log('User ID is', userId);
        this.oneSignalId = typeof(userId) == 'number' ? userId.toString() : userId;
      }
    );
  }

  private subscribe(): void {
    OneSignal.push((): void => {
        console.log('Register For Push');
        OneSignal.push(['registerForPushNotifications']);
        OneSignal.on('subscriptionChange', 
          (isSubscribed: boolean): void => {
            console.log('The user\'s subscription state is now:', isSubscribed);
            this.listenForNotification();
            OneSignal.getUserId().then(
              (userId: string | number): void => {
                console.log('User ID is', userId);
                this.oneSignalId = typeof(userId) == 'number' ? userId.toString() : userId;
                this.updateLocalUserProfile();
                this.setUserID();
              }
            );
          }
        );
      }
    );
  }

  private listenForNotification(): void {
    console.log('Initalize Listener');
    OneSignal.on('notificationDisplay', 
      (event: string): void => {
        console.log('OneSignal notification displayed:', event);
        this.listenForNotification();
      }
    );
  }

  private updateLocalUserProfile(): void {
    this.cookieService.put('onesignalId', this.oneSignalId)
  }
}