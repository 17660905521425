import { Component } from '@angular/core';

@Component({
  selector: 'flightclass-footer',
  styleUrls: ['./footer.component.scss'],
  template: `
    <span class="created-by">Todos os direitos reservados &#x2117; 2023 <b> Flight Class </b></span>
    <span class="terms-use"><b><a href="/termos-de-uso" target="_blank">Termos de uso</a></b></span>
  `,
})
export class FooterComponent {
}
