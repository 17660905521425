import { Answer } from "./answer";
import { Product } from "./product";

export class Lesson {
    name: string;
    thumb?: string;
    type?: string;
    duration?: number;
    free?: boolean;

    product_id?: number;
    grouplesson_id?: number;
    order?: number;

    description?: string;
    short_desc?: string;

    youtube_id?: string;
    video_name?: string;
    file?: string;
    
    kcal?: string;
    url_video?: string;
    plyer_io?: boolean;
    lesson_seen?: boolean;

    answers?: Answer[];
    completed?: boolean;
    saved?: boolean;

    id?: number;

    product?: Product;
};





