import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

@Injectable()
export class EmitterService {

  private subject: Subject<any> = new Subject<any>();

  sendClickEvent(value: any): void {
    this.subject.next(value);
  }

  getClickEvent(): Observable<any>{ 
    return this.subject.asObservable();
  }

  sendSecondClickEvent(value: any): void {
    this.subject.next(value);
  }

  getSecondClickEvent(): Observable<any>{ 
    return this.subject.asObservable();
  }

  sendFilterEvent(): void {
    this.subject.next(true);
  }

  getFilterEvent(): Observable<any>{ 
    return this.subject.asObservable();
  }
}