import { Injectable } from '@angular/core';

@Injectable()
export class StorageService {

  store(value: string, key: string): void {
    localStorage.setItem(key, value);
  }

  restore(key: string): string {
    return localStorage.getItem(key);
  }

  storeObject(value: any, key: string) {
    localStorage.setItem(key, JSON.stringify(value));
  }

  restoreObject(key: string): any {
    return JSON.parse(localStorage.getItem(key));
  }
}
