import { NgModule, ModuleWithProviders } from '@angular/core';
import { CommonModule } from '@angular/common';

import { UserService } from './users.service';
import { StateService } from './state.service';
import { CookieService } from 'angular2-cookie/core';
import { LayoutService } from './layout.service';
import { SmartTableFilterService } from './smart-table-filter.service';
import { StorageService } from './storage.service';

import { OneSignalService } from './onesignal.service';
import { LoginService } from './login.service';
import { GeneralService } from './general.service';
import { DeviceService } from './device.service';
import { BackupService } from './backup.service';
import { EmitterService } from './emitter.service';
import { BucketService } from './bucket.service';

export function cookieServiceFactory(): CookieService {
  return new CookieService();
}

const SERVICES: any[] = [
  UserService,
  StateService,
  LoginService,
  LayoutService,
  GeneralService,
  DeviceService,
  SmartTableFilterService,
  OneSignalService,
  StorageService,
  BackupService,
  EmitterService,
  BucketService,
  { provide: CookieService, useFactory: cookieServiceFactory }
];

@NgModule({
  imports: [
    CommonModule
  ],
  providers: [
    ...SERVICES,
  ],
})
export class DataModule {
  static forRoot(): ModuleWithProviders {
    return <ModuleWithProviders>{
      ngModule: DataModule,
      providers: [
        ...SERVICES,
      ],
    };
  }
}
